<template>
   <div class="dataList">
         <table class="stockList">
             <thead>
                  <tr>
                     <td>股票代码</td>
                     <td>股票名称</td>
                     <td>数量(股)</td>
                     <td>持仓更新时间</td>
                  </tr>
               </thead>
               <tbody>
                  <tr v-for="(item,index) in dataList" :key="index">
                     <td>{{item.code}}</td>
                     <td>{{item.name}}</td>
                     <td>{{item.number}}</td>
                     <td>{{item.updateTime | timestampFormatToTime}}</td>
                  </tr>
               </tbody>
         </table>
   </div>
</template>

<script>
export default {
   data(){
      return {
         dataList:[],

         webSocket:null,           // websocket对象
         webSocketStatus:"未连接", // websocket连接状态
         reconnectNumber:0,        // websocket重连次数
      }
   },
   mounted(){
      this.webSocketConnect();
   },
   methods:{

      // .0 建立webSocket连接 -------------------------------------------------------------
      webSocketConnect(){

         let that = this;

         // 官方文档：https://developer.mozilla.org/zh-CN/docs/Web/API/WebSocket
         this.webSocketStatus = "正在连接……";
         if(this.$env === "production"){
            this.webSocket = new WebSocket("ws://118.24.103.166:49151");
         }else{
            this.webSocket = new WebSocket("ws://127.0.0.1:49151");
         }
         
         // 连接成功回调
         this.webSocket.onopen = function(evt) { 
            that.reconnectNumber = 0;
            that.webSocketStatus = "连接成功";
            that.webSocket.send(JSON.stringify({
               token:'123456'
            }));
            that.webSocket.send(JSON.stringify({
               operationType:'holdingList'
            }));
         };
         
         // 收到服务器数据回调
         this.webSocket.onmessage = function(evt) {
            try {
               that.dataList = JSON.parse(evt.data);
            } catch(e) {
               console.log(evt.data);
            }            
         };
         
         // 连接关闭后回调
         this.webSocket.onclose = function(evt) {
            that.webSocketStatus = "连接关闭";
         };    
         // 连接出错后回调
         this.webSocket.onerror = function(evt) {
            that.webSocket.close();
            that.webSocketStatus = "连接失败";
            that.webSocketReconnect(); // 连接失败自动重连
         };    
      },
      
      // 2.1 webSocket重新连接 -------------------------------------------------------------
      webSocketReconnect(){
         ++this.reconnectNumber;
         this.webSocket = null;
         this.webSocketConnect();
      }
   },
   // Vue生命周期：页面离开之前被调用
   beforeDestroy: function () {
      this.webSocket.close();
      this.webSocket = null;
   }
}
</script>

<style scoped>

.dataList{
   overflow-y: auto;
   display:flex;
   flex-direction:column;
   flex-grow: 1;
   overflow:auto;
   height: 0px;  /* 解决flex下scroll无法滚动的问题 */
   padding-bottom:10px;
}

table.stockList{
   width: 100%;
   height: 100%;
}
table.stockList tbody {
   display:block;
   overflow:auto;
   height: calc(100% - 26px);
}
table.stockList thead tr{
   font-weight: bold;
}
table.stockList thead, tbody tr {
    display:table;
    width:100%;
    table-layout:fixed; /* even columns width , fix width of table too*/
}
table.stockList tbody tr:hover{
   background-color: rgb(245, 245, 245);
}


</style>