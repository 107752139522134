<template>
   <div class="runStatusBar">
      <div class="dataItem" :style="{'order':item.order}" v-for="(item,index) in dataList" :key="index" :class="{bottomBorder:item.key.indexOf('DataClear')!=-1}">
         <div class="title">{{item.title}}</div>
         <div class="time" v-if="item.key.indexOf('Static')!=-1 || item.key.indexOf('DataClear')!=-1 || item.key.indexOf('Clearance')!=-1">{{item.time | timestampFormatToDateTime}}</div>
         <div class="time" v-else>{{item.timeDuration}}前</div>
         <div class="status" :class="item.status">{{item.status}}</div>
      </div>
   </div>
</template>

<script>
export default {
    data(){
      return {
         dataList:[],
         timer:null,

         webSocket:null,           // websocket对象
         webSocketStatus:"未连接", // websocket连接状态
         reconnectNumber:0,        // websocket重连次数
      }
   },
   mounted(){

      let that = this;
      this.timer = setInterval(()=>{
         if(that.dataList.length > 0){
            for(let i=0;i<that.dataList.length;i++){
               that.dataList[i].timeDuration = that.$common.getTimeDuration(that.dataList[i].time)
            }
         }
      },1000); // 毫秒

      this.webSocketConnect();
   },
   methods:{

      // .0 建立webSocket连接 -------------------------------------------------------------
      webSocketConnect(){

         let that = this;

         // 官方文档：https://developer.mozilla.org/zh-CN/docs/Web/API/WebSocket
         this.webSocketStatus = "正在连接……";
         if(this.$env === "production"){
            this.webSocket = new WebSocket("ws://118.24.103.166:49151");
         }else{
            this.webSocket = new WebSocket("ws://127.0.0.1:49151");
         }
         
         // 连接成功回调
         this.webSocket.onopen = function(evt) { 
            that.reconnectNumber = 0;
            that.webSocketStatus = "连接成功";
            that.webSocket.send(JSON.stringify({
               token:'123456'
            }));
            that.webSocket.send(JSON.stringify({
               operationType:'runStatus'
            }));
         };
         
         // 收到服务器数据回调
         this.webSocket.onmessage = function(evt) {
            try {
               let data = JSON.parse(evt.data);
               that.dataList = that.dataProcessing(data)
            } catch(e) {
               console.log(evt.data);
            }            
         };
         
         // 连接关闭后回调
         this.webSocket.onclose = function(evt) {
            that.webSocketStatus = "连接关闭";
         };    
         // 连接出错后回调
         this.webSocket.onerror = function(evt) {
            that.webSocket.close();
            that.webSocketStatus = "连接失败";
            that.webSocketReconnect(); // 连接失败自动重连
         };    
      },
      
      // 2.1 webSocket重新连接 -------------------------------------------------------------
      webSocketReconnect(){
         ++this.reconnectNumber;
         this.webSocket = null;
         this.webSocketConnect();
      },
      
      // 3.0 对返回数据进行排序、处理 ---------------------------------------------------------
      dataProcessing(data){

         let array = []
         for(let index in data){
            let item = JSON.parse(data[index]);
            item.timeDuration = this.$common.getTimeDuration(item.time);
            switch(item.key){
               case "StockStaticInfo_US":{
                  item.order = 1
                  break;
               }
               case "StockDynamicInfo_US":{
                  item.order = 2
                  break;
               }
               case "ScreenStocks_US":{
                  item.order = 3
                  break;
               }
               case "Clearance_US":{
                  item.order = 4
                  break;
               }
               case "DataClear_US":{
                  item.order = 5
                  break;
               }
               case "StockStaticInfo_HK":{
                  item.order = 100
                  break;
               }
               case "StockDynamicInfo_HK":{
                  item.order = 101
                  break;
               }
               case "ScreenStocks_HK":{
                  item.order = 102
                  break;
               }
               case "Clearance_HK":{
                  item.order = 103
                  break;
               }
               case "DataClear_HK":{
                  item.order = 104
                  break;
               }
               case "Ticker_Futu_Update":{
                  item.order = 1000
                  break;
               }
               case "Quote_Futu_Update":{
                  item.order = 1001
                  break;
               }
               case "Kline_Futu_Update":{
                  item.order = 1001
                  break;
               }
               case "OrderBook_Futu_Update":{
                  item.order = 1001
                  break;
               }
               case "Ticker_Futu":{
                  item.order = 1002
                  break;
               }
               case "Quote_Futu":{
                  item.order = 1003
                  break;
               }
               case "Kline_Futu":{
                  item.order = 1004
                  break;
               }
               case "OrderBook_Futu":{
                  item.order = 1005
                  break;
               }
               case "Analysis":{
                  item.order = 1006
                  break;
               }
            }
            

            array.push(item)
         }
         return array;
      }
   },
   // Vue生命周期：页面离开之前被调用
   beforeDestroy: function () {
      this.webSocket.close();
      this.webSocket = null;

      clearInterval(this.timer);
   }
}
</script>


<style scoped>

.runStatusBar{
   padding:10px 0px;
   box-sizing: border-box;
   display:flex;
   flex-direction: column;
}
.dataItem{
   display:flex;
   padding:6px 10px;
   font-weight:bold;
}
.dataItem:hover{
   background-color:rgb(230, 230, 230);
}
.dataItem>.title{
   width:50%;
}
.dataItem>.time{
   width:46%;
}
.dataItem>.status{
   width:20%;
   color:red;
}
.dataItem>.status.SUCCESS{
   color:green;
}
.dataItem.bottomBorder{
   border-bottom:1px solid rgb(205, 205, 205);
   margin-bottom:4px;
}


</style>