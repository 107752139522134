<template>
   <div class="container">
      <div class="header">
         <div class="item active">美股({{beAnalyzedStockList.length}}/{{usStockTotal}})</div>
         <div class="item">港股({{beAnalyzedStockList.length}}/{{hkStockTotal}})</div>
         <div class="item" v-if="reconnectNumber>0">{{webSocketStatus}}{{reconnectNumber}}</div>

         <div class="item" style="margin-left:auto">港股时间：{{stockTime_hk | timestampFormatToDateTime}}</div>
         <div class="item">美股时间：{{stockTime_us | timestampFormatToDateTime}}</div>
      </div>

      <div class="content">

         <!-- 股票列表 -->
         <div class="stockList us">
            <table class="stockList">
                  <thead>
                     <tr>
                        <!-- <td>序号</td> -->
                        <td style="width:280px">股票名称</td>
                        <td>最新成交价</td>
                        <td>最新成交量</td>
                        <td>最新价更新时间</td>
                        <td>市值</td>
                        <td>量比</td>
                        <td>换手率</td>
                        <td>振幅</td>
                     </tr>
                  </thead>
                  <tbody>
                     <tr v-for="(item,index) in beAnalyzedStockList" :key="index">
                        <!-- <td>{{index+1}}</td> -->
                        <td style="width:280px">
                           <div>{{item.stockStaticInfo.name}}</div>
                           <div style="font-size:12px;color:gray;">{{item.ticker.s}}</div>
                        </td>
                        <td>{{item.ticker.p.toFixed(2)}}</td>
                        <td>{{item.ticker.v/1000}}K({{parseInt(item.ticker.p * item.ticker.v)}})</td>
                        <td>{{item.ticker.t | timestampFormatToTime}}</td>
                        <td v-if='item.stockDynamicInfo'>{{item.stockDynamicInfo.total_market_val | numberAddFinancialUnit}}</td>
                        <td v-if='item.stockDynamicInfo'>{{item.stockDynamicInfo.volume_ratio}}</td>
                        <td v-if='item.stockDynamicInfo'>{{item.stockDynamicInfo.turnover_rate}}%</td>
                        <td v-if='item.stockDynamicInfo'>{{item.stockDynamicInfo.amplitude}}%</td>
                     </tr>
                  </tbody>
            </table>
         </div>

         <div class="otherContent">
            
            <!-- 运行状态栏 -->
            <run-status-bar></run-status-bar>

            <el-divider content-position="center">持仓</el-divider>

            <!-- 持仓列表 -->
            <holding-list></holding-list>
         </div>


      </div>
      
   </div>
</template>

<script>

import RunStatusBar from './RunStatusBar'
import HoldingList from './HoldingList'

export default {
   components:{
      RunStatusBar,
      HoldingList
   },
   data(){
      return {
         beAnalyzedStockList:[],
         hkStockTotal:[],
         usStockTotal:[],

         stockTime_hk:0,
         stockTime_us:0,

         webSocket:null,           // websocket对象
         webSocketStatus:"未连接",  // websocket连接状态
         reconnectNumber:0,        // websocket重连次数
      }
   },
   mounted(){
      this.webSocketConnect();
   },
   methods:{
      // .0 建立webSocket连接 -------------------------------------------------------------
      webSocketConnect(){

         let that = this;

         // 官方文档：https://developer.mozilla.org/zh-CN/docs/Web/API/WebSocket
         this.webSocketStatus = "正在连接……";
         if(this.$env === "production"){
            this.webSocket = new WebSocket("ws://118.24.103.166:49151");
         }else{
            this.webSocket = new WebSocket("ws://127.0.0.1:49151");
         }
         
         // 连接成功回调
         this.webSocket.onopen = function(evt) { 
            that.reconnectNumber = 0;
            that.webSocketStatus = "连接成功";
            that.webSocket.send(JSON.stringify({
               token:'123456'
            }));
            that.webSocket.send(JSON.stringify({
               operationType:'stockAnalysisDetails'
            }));
         };
         
         // 收到服务器数据回调
         this.webSocket.onmessage = function(evt) {
            try {
               let data = JSON.parse(evt.data);
               that.hkStockTotal = data.hkStockTotal;
               that.usStockTotal = data.usStockTotal;
               that.stockTime_hk = data.stockTime_hk;
               that.stockTime_us = data.stockTime_us;

               that.beAnalyzedStockList = [];
               data.stockList.forEach((ele)=>{
                  that.beAnalyzedStockList.push({
                     'stockDynamicInfo':JSON.parse(ele.stockDynamicInfo),
                     'stockStaticInfo':JSON.parse(ele.stockStaticInfo),
                     'ticker':ele.ticker
                  });
               });
            } catch(e) {
               console.log(evt.data);
            }            
         };
         
         // 连接关闭后回调
         this.webSocket.onclose = function(evt) {
            that.webSocketStatus = "连接关闭";
         };    
         // 连接出错后回调
         this.webSocket.onerror = function(evt) {
            that.webSocket.close();
            that.webSocketStatus = "连接失败";
            that.webSocketReconnect(); // 连接失败自动重连
         };    
      },
      
      // 2.1 webSocket重新连接 -------------------------------------------------------------
      webSocketReconnect(){
         ++this.reconnectNumber;
         this.webSocket = null;
         this.webSocketConnect();
      }
   },
   // Vue生命周期：页面离开之前被调用
   beforeDestroy: function () {
      this.webSocket.close();
      this.webSocket = null;
   }
   
}
</script>

<style scoped>

.container{
   display:flex;
   flex-direction:column;
}
.container>.header{
   padding:8px 18px;
   display:flex;
   flex-direction: row;
   border-bottom: 1px solid #e6e6e6;
}
.container>.header>.item{
   margin-right: 50px;
   cursor: pointer;
}
.container>.header>.item:hover{
   color:#407fea;
}
.container>.header>.item.active{
   color:#407fea;
}
.container>.content{
   display:flex;
   flex-direction:row;
   flex-grow: 1;
}
.container>.content>.stockList{
   overflow:auto;
}
.container>.content>.otherContent{
   width: 400px;
   min-width: 400px;
   display: flex;
   flex-direction: column;
   border-left: 1px solid rgb(193, 193, 193);
}

table.stockList{
   width: 100%;
   height: 100%;
}
table.stockList tbody {
   display:block;
   overflow:auto;
   height: calc(100% - 26px);
}
table.stockList thead tr{
   font-weight: bold;
}
table.stockList thead, tbody tr {
    display:table;
    width:100%;
    table-layout:fixed; /* even columns width , fix width of table too*/
}
table.stockList tbody tr:hover{
   background-color: rgb(245, 245, 245);
}
/* table.stockList tr>td:nth-child(1){
   width:80px;
   text-align:center;
} */


   
</style>